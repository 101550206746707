<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup>
    import { useHeadSafe } from '#imports';
    import 'virtual:svg-icons-register';

    useHeadSafe({
        htmlAttrs: {
            lang: 'en',
        },
        // link: [
        //     { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
        //     { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
        //     { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
        //     { rel: 'manifest', href: '/site.webmanifest', crossorigin: 'use-credentials' },
        //     { rel: 'shortcut icon', href: '/favicon.ico' },
        // ],
    });
</script>

<style lang="scss">
    @use '@/styles/critical.scss';
    @use '@/styles/main.scss';
</style>
