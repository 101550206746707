
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _4048VE5ReAcbXMeta } from "/vercel/path0/apps/frontend/src/pages/404.vue?macro=true";
import { default as client_45mode_45irUwXrGoOFDHMeta } from "/vercel/path0/apps/frontend/src/pages/client-mode-ir.vue?macro=true";
import { default as client_45mode_45weE24BcCX1OkMeta } from "/vercel/path0/apps/frontend/src/pages/client-mode-we.vue?macro=true";
import { default as client_45modei37hfxTUThMeta } from "/vercel/path0/apps/frontend/src/pages/client-mode.vue?macro=true";
import { default as iframe_45exampleNAw8GQzT9RMeta } from "/vercel/path0/apps/frontend/src/pages/iframe-example.vue?macro=true";
import { default as indexxKA15Za1fFMeta } from "/vercel/path0/apps/frontend/src/pages/index.vue?macro=true";
import { default as marketing_45ripsU00GEpJcE2Meta } from "/vercel/path0/apps/frontend/src/pages/marketing-rips.vue?macro=true";
import { default as _91_46_46_46slug_93e0LI7qhsfYMeta } from "/vercel/path0/apps/frontend/src/pages/sandbox/[...slug].vue?macro=true";
import { default as white_45elephantonROWlxzb7Meta } from "/vercel/path0/apps/frontend/src/pages/white-elephant.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _4048VE5ReAcbXMeta || {},
    component: () => import("/vercel/path0/apps/frontend/src/pages/404.vue")
  },
  {
    name: "client-mode-ir",
    path: "/client-mode-ir",
    meta: client_45mode_45irUwXrGoOFDHMeta || {},
    component: () => import("/vercel/path0/apps/frontend/src/pages/client-mode-ir.vue")
  },
  {
    name: "client-mode-we",
    path: "/client-mode-we",
    meta: client_45mode_45weE24BcCX1OkMeta || {},
    component: () => import("/vercel/path0/apps/frontend/src/pages/client-mode-we.vue")
  },
  {
    name: "client-mode",
    path: "/client-mode",
    meta: client_45modei37hfxTUThMeta || {},
    component: () => import("/vercel/path0/apps/frontend/src/pages/client-mode.vue")
  },
  {
    name: "iframe-example",
    path: "/iframe-example",
    component: () => import("/vercel/path0/apps/frontend/src/pages/iframe-example.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexxKA15Za1fFMeta || {},
    component: () => import("/vercel/path0/apps/frontend/src/pages/index.vue")
  },
  {
    name: "marketing-rips",
    path: "/marketing-rips",
    meta: marketing_45ripsU00GEpJcE2Meta || {},
    component: () => import("/vercel/path0/apps/frontend/src/pages/marketing-rips.vue")
  },
  {
    name: "sandbox-slug",
    path: "/sandbox/:slug(.*)*",
    meta: _91_46_46_46slug_93e0LI7qhsfYMeta || {},
    component: () => import("/vercel/path0/apps/frontend/src/pages/sandbox/[...slug].vue")
  },
  {
    name: "white-elephant",
    path: "/white-elephant",
    meta: white_45elephantonROWlxzb7Meta || {},
    component: () => import("/vercel/path0/apps/frontend/src/pages/white-elephant.vue")
  }
]