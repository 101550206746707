import payload_plugin_ZruSkkLBMJ from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Cf4kaY8Ja5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.5.1_db0@0.2.3_eslint@9.11.1_jiti@2.4.2__iore_kxmmj2li3kv6yq62pppntuvrky/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_REnb12H0Ne from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.5.1_db0@0.2.3_eslint@9.11.1_jiti@2.4.2__iore_kxmmj2li3kv6yq62pppntuvrky/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tZ2g0qi2W0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.5.1_db0@0.2.3_eslint@9.11.1_jiti@2.4.2__iore_kxmmj2li3kv6yq62pppntuvrky/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_sGRwBf8Uiz from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_vite@6.1.0_@types+node@20.5.1_jiti@2.4.2_sass@1.84.0_te_vevzwpiuwxculqbzxrxhzsmq2i/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_62WyMmwvbr from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.5.1_db0@0.2.3_eslint@9.11.1_jiti@2.4.2__iore_kxmmj2li3kv6yq62pppntuvrky/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UnTjJUWGyz from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.5.1_db0@0.2.3_eslint@9.11.1_jiti@2.4.2__iore_kxmmj2li3kv6yq62pppntuvrky/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RMowIQoKvR from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.5.1_db0@0.2.3_eslint@9.11.1_jiti@2.4.2__iore_kxmmj2li3kv6yq62pppntuvrky/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jgRdQM9rSs from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.5.1_db0@0.2.3_eslint@9.11.1_jiti@2.4.2__iore_kxmmj2li3kv6yq62pppntuvrky/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_SJO324ESlt from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_EdElDQiPjx from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.5.1_db0@0.2.3_eslint@9.11.1_jiti@2.4.2__iore_kxmmj2li3kv6yq62pppntuvrky/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  payload_plugin_ZruSkkLBMJ,
  revive_payload_client_Cf4kaY8Ja5,
  unhead_REnb12H0Ne,
  router_tZ2g0qi2W0,
  _0_siteConfig_sGRwBf8Uiz,
  payload_client_62WyMmwvbr,
  navigation_repaint_client_UnTjJUWGyz,
  check_outdated_build_client_RMowIQoKvR,
  chunk_reload_client_jgRdQM9rSs,
  plugin_vue3_SJO324ESlt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EdElDQiPjx
]